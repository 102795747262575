<template>
  <div class="ChangePasswordView">
    <MyAccountView />
    <div class="o-container-gray">
      <div v-if="isResetPasswordLoading">
        <h3 class="u-justify-content-center u-text-center">{{ $t('changePasswordView.changePasswordInProgress') }}</h3>
        <Loader />
      </div>
      <div v-else>
        <div v-if="changePasswordRequestSent" class="u-text-center">
          <img src="@/assets/images/checkmark.png" alt="Checkmark image" class="success-image" />
          <h3 class="o-default-h3 password-confirmation-h3">{{ $t('changePasswordView.passwordSuccessfullyChanged') }}</h3>
          <div class="navigate-btn">
            <md-button class="md-raised md-primary navigate-home-btn u-display-block"
                       @click="Helpers.goToRoute('', $router)">
                       {{ $t('resetPasswordView.mainPage') }}
            </md-button>
            <md-button class="md-raised navigate-account-btn u-display-block"
                       @click="Helpers.goToRoute('my-account', $router)">
                       {{ $t('resetPasswordView.myAccount') }}
            </md-button>
          </div>
        </div>
        <div v-else>
          <form novalidate class="md-layout" @submit.prevent="validatePassword">
            <md-card class="change-password-card">
              <md-progress-bar md-mode="indeterminate" v-if="isAuthLoading" />
              <md-card-header>
                <div class="md-title">{{ $t('changePasswordView.title') }}</div>
              </md-card-header>

              <md-card-content>
                <md-field :class="getValidationClass('oldPassword')">
                  <label>{{ $t('changePasswordView.password') }}</label>
                  <md-input v-model="oldPassword" type="password" name="oldPassword" id="oldPassword">
                  </md-input>
                  <span class="md-error" v-if="!$v.oldPassword.required">{{ $t('common.auth.enterYourPassword') }}</span>
                  <span class="md-error" v-else-if="!$v.oldPassword.minLength">{{ $t('changePasswordView.minLengthPassword') }}</span>
                </md-field>

                <md-field :class="getValidationClass('newPassword1')">
                  <label>{{ $t('changePasswordView.newPassword') }}</label>
                  <md-input v-model="newPassword1" type="password" name="newPassword1" id="newPassword1" />
                  <span class="md-error" v-if="!$v.newPassword1.required">{{ $t('common.auth.enterYourPassword') }}</span>
                  <span class="md-error" v-else-if="!$v.newPassword1.minLength">{{ $t('changePasswordView.minLengthPassword') }}</span>
                </md-field>

                <md-field :md-toggle-password="false" :class="getValidationClass('newPassword2')">
                  <label>{{ $t('changePasswordView.confirmPassword') }}</label>
                  <md-input v-model="newPassword2" type="password" name="newPassword2" id="newPassword2" />
                  <span class="md-error" v-if="!$v.newPassword2.required">{{ $t('changePasswordView.enterPasswordAgain') }}</span>
                  <span class="md-error" v-else-if="!$v.newPassword2.sameAsPassword">{{ $t('changePasswordView.passwordsMustBeIdentical') }}</span>
                </md-field>
              </md-card-content>

              <div v-if="errors" v-html="errors" class="u-default-body u-red"></div>
              <vue-recaptcha ref="invisibleRecaptcha"
                             @verify="onVerify"
                             @expired="onExpired"
                             size="invisible"
                             :sitekey="sitekey">
              </vue-recaptcha>
              <md-card-actions class="u-justify-content-center u-text-center">
                <md-button type="submit" class="md-raised md-primary send-btn">{{ $t('common.actions.save') }}</md-button>
              </md-card-actions>
            </md-card>
            <!-- <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar> -->
          </form>
        </div>
      </div>
      <div class="change-password-footer" :class="{ 'u-justify-content-center u-text-center': changePasswordRequestSent }">
        <p class="o-default-body">{{ $t('forgotPasswordView.ifIssuesEncountered') }} {{ $t('contactEmailInreg') }}</p>
        <SecureLock class="secure-lock" />
      </div>
    </div> <!-- /.o-container-gray -->
  </div> <!-- /.ChangePasswordView -->
</template>

<script>
import { mapState } from 'vuex';
import {
  START_AUTH_LOADER,
  CHANGE_PASSWORD,
  RESET_AUTH_ERROR
} from '@/store/actions.type';
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
  sameAs
} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';
import SecureLock from '@/components/util/SecureLock';
import Loader from '@/components/util/Loader';
import MyAccountView from '@/views/my-account/MyAccountView';

export default {
  name: 'ChangePasswordView',
  mixins: [validationMixin],
  components: {
    Loader,
    VueRecaptcha,
    SecureLock,
    MyAccountView,
  },
  data() {
    return {
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
      changePasswordRequestSent: false,
      snackbar: {
        show: false,
        position: 'center',
        duration: 4000,
        isInfinity: false
      },
      sitekey: '6LehkZMUAAAAAOua6fmkshH_FUe0rkw0v45UXjIS'
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isAuthLoading: state => state.auth.isAuthLoading,
      isResetPasswordLoading: state => state.auth.isResetPasswordLoading
    })
  },
  mounted() {
    this.resetErrors();
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.oldPassword = null
      this.newPassword1 = null
      this.newPassword2 = null
    },
    validatePassword() {
      this.$v.$touch()

      this.$refs.invisibleRecaptcha.execute();
    },
    sendResetPasswordRequest(response) {
      this.$store.dispatch(START_AUTH_LOADER);

      this.$store.dispatch(CHANGE_PASSWORD, {
          password: this.newPassword1,
          oldPassword: this.oldPassword,
          captchaToken: response
        })
        .then(() => {
          this.changePasswordRequestSent = true;
        });
    },
    onVerify(response) {
      this.$refs.invisibleRecaptcha.reset();

      if (!this.$v.$invalid) {
        this.sendResetPasswordRequest(response);
      }
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset();

      // eslint-disable-next-line
      console.log('Expired');
    },
    resetErrors() {
      this.$store.dispatch(RESET_AUTH_ERROR);
    }
  },
  validations: {
    oldPassword: {
      required,
      minLength: minLength(6),
    },
    newPassword1: {
      required,
      minLength: minLength(6),
    },
    newPassword2: {
      required,
      sameAsPassword: sameAs('newPassword1'),
    },
  },
};
</script>

<style lang="scss" scoped>
.ChangePasswordView {

  .change-password-card {
    margin: 0 auto;
    padding: 1.6rem;
    width: 70rem;
  }

  .change-password-footer {
    margin-top: 1rem;
  }

  .secure-lock {
    margin: 1.6rem 0;
  }

  .send-btn {
    background-color: $palette-primary-color-three !important;
    width: 100%;
    height: 4rem;
  }

  .navigate-btn {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .navigate-home-btn {
    background-color: $palette-primary-color-four !important;
    border-radius: .4rem;
    box-shadow: none;
    font-family: $font-family-heading-regular;
    height: 3.8rem;
    letter-spacing: .1rem;
    margin: 1rem;
    min-width: 25rem;
    text-transform: none;
  }

  .navigate-account-btn {
    margin: 1.6rem;
    min-width: 25rem;
    text-transform: none;
  }

  .success-image {
    height: 6rem;
    margin: 0 auto 1rem;
  }

  .password-confirmation-h3 {
    margin: 1rem auto;
    padding-bottom: 0;
    text-align: center;
  }
}
</style>
